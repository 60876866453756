import React, { useState } from 'react';

const ContactForm = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-form', ...formState }),
    })
      .then(() => alert('Success! Your message has been sent.'))
      .catch(error => alert(error));
  };

  return (
    <form
      onSubmit={handleSubmit}
      name="contact-form"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <input
        id="name"
        name="name"
        type="text"
        onChange={handleChange}
        value={formState.name}
        className="feedback-input"
        placeholder="Your Name"
      />
      <input
        id="email"
        name="email"
        type="email"
        onChange={handleChange}
        value={formState.email}
        className="feedback-input"
        placeholder="Enter Your Email"
      />
      <textarea
        id="message"
        name="message"
        onChange={handleChange}
        value={formState.message}
        className="feedback-input"
        placeholder="Message"
      />
      <button type="submit" className="button">Send</button>
    </form>
  );
};

export default ContactForm;