import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import ContactForm from '../../components/ContactForm';

const Contact = () => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact" />
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contact</h1>
            <section id="contactUs" className="main special">
              <header className="major">
                <ContactForm />
              </header>
            </section>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
